// links
@mixin linkStyles($color, $visitedStyles: '', $underlined: '') {
    background-color: transparent;
    transition: $transition-std;
    color: $color;
    text-decoration: underline;

    @if $underlined == 'none' {
        text-decoration: none;
    }

    @if $visitedStyles != 'none' {
        &:visited {
            color: darken($color, 25%);
        }
    }

    &:hover {
        color: lighten($color, 20%);
        transition: $transition-std;
    }
    &:active {
        color: $secondary;
    }
}
