@import './variables.module.scss';
@import './specific/linkStyles.module.scss';

/*
    note: these styles are imported everywhere throughout the entire app
    - use sparingly to prevent lagging the site
*/

@mixin bottomSpacing {
    margin: 0 0 1em 0;

    &:last-child {
        margin-bottom: 0;
    }
}

*,
::before,
::after {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // makes 1rem = 10px
    box-sizing: border-box;
    scroll-behavior: smooth;

    /* Prevent font scaling in landscape while allowing user zoom - without it, cookie banner text size (for example) is misaligned with the links there */
    -webkit-text-size-adjust: 100%; /* For Safari */
    -moz-text-size-adjust: 100%; /* For Firefox */
    -ms-text-size-adjust: 100%; /* For Internet Explorer */
    text-size-adjust: 100%;
}

body {
    font-size: 1.8em; // default font-size: 18px - perhaps ensure it's the same as $font-std but here it should be in em, not rem
    font-family: $font-family-std;
    line-height: 1.5;
    color: $text;
}

// fonts
h1 {
    font-size: $font-lg-3;
}
h2 {
    font-size: $font-lg-2;
}
h3 {
    font-size: $font-lg-1;
}
h4,
p,
li,
input,
label,
textarea,
select {
    font-size: $font-std;
}
h5 {
    font-size: $font-small;
}
h6 {
    font-size: $font-tiny-1;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
ol {
    @include bottomSpacing;
}
::selection {
    background-color: $text-highlight;
}

// lists
ul,
ol {
    padding-left: 2em;

    li {
        overflow: visible;
    }

    &.spaced li {
        margin-bottom: 1em;

        &:last-child {
            @include bottomSpacing;
        }
    }
}

// links
a {
    @include linkStyles($links);
}
