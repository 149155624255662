@import '../../../css/variables.module.scss';

// situations: errors & successes
.situationText {
    color: $text;
    font-weight: 400;
    padding: 0.5em 0.75em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: calc(100vw - 2em);
    border: 1px solid;
    border-radius: $border-radius;
    width: fit-content;

    &.centralised {
        margin-left: auto;
        margin-right: auto;
    }

    &.error {
        background-color: $red-light;
        border-color: $border-red;
    }

    &.success {
        background-color: $primary-very-light;
        border-color: $primary-light;
    }

    &.warning {
        background-color: $orange-light;
        border-color: $border-orange;
    }

    &.info {
        background-color: $primary-very-light;
        border-color: $primary-light;

        ::selection {
            background-color: $red-soft;
        }
    }

    > span {
        font-size: $font-std;
        margin-left: 0.75em;
        opacity: 0.8;
    }

    svg {
        font-size: $font-lg-3;
        opacity: 0.8;
    }

    &.marginTop {
        margin-top: 1em;
    }

    &.marginBottom {
        margin-bottom: 1em;
    }
}

.noneFound {
    margin-top: 1em;

    &.centralised {
        text-align: center;
    }
}

.loading {
    &.centralised {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.centralisedEntirePage {
        width: 100%;
        min-height: $viewport-height-adjusted;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.marginTop {
        margin-top: 1em;
    }

    &.marginBottom {
        margin-bottom: 1em;
    }
}

.errorText {
    color: $red;

    &.marginBottom {
        margin-bottom: 1em;
    }

    &.marginTop {
        margin-top: 1em;
    }
}
