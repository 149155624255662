// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
//https://fonts.google.com/specimen/Open+Sans?stroke=Sans+Serif
// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
//https://fonts.google.com/specimen/Poppins

// commented out below since it's added to helmet
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700&display=swap');

// dimensions
// widths
$content-padding: 20px;
$max-content-width: 1160px + ($content-padding * 2);
$larger-max-content-width: 1360px + ($content-padding * 2);
$content-vertical-padding: 7.5rem;
// heights
$header-height: 80px;
// $header-padding: 30px;
$viewport-height-adjusted: calc(100vh - #{$header-height});

// colours
// std
$grey-light: #f2f2f2;
$grey-medium: #e5e5e5;
$grey-dark: #ccc;
$grey-very-dark: #a2a2a2;
$white: #fff;
// brand
$red: #e53935;
$red-soft: #f7adad;
$green-soft: #8ed99f;
$orange: #ffb74d;
$blue: #0000ff;
$primary: #2da587;
$secondary: #4f4f4f;
$green: $primary;
// $text: #333;
$text: $secondary;
$links: darken($blue, 25%);
// light
$primary-light: lighten($primary, 15%);
$primary-very-light: lighten($primary, 35%);
$primary-ultra-light: lighten($primary, 50%);
$red-light: #ffcdd2;
$green-light: #c8e6c9;
$orange-light: #ffe6bf;
// misc
$site-bg-colour: rgb(248, 251, 250);
$green-tinted: #eaf5f0;
$text-highlight: $primary-light;
$shadow-color: $primary;
$box-shadow-color: rgb(21, 143, 88);
$box-shadow-std: rgba($box-shadow-color, 0.03) 1px 1px 4px 0px,
    rgba($box-shadow-color, 0.05) 4px 5px 16px 0px, rgba($box-shadow-color, 0.08) 11px 12px 40px 0px;

// borders
$border-colour: $grey-dark;
$border-radius: 10px;
$border-std: 1px solid $border-colour;
$border-orange: #ffb74d;
$border-green: #43a047;
$border-red: #e57373;
$border-blue: #1976d2;

// fonts
// sizes
$font-lg-5: 5rem;
$font-lg-4: 3.6rem;
$font-lg-3: 3rem;
$font-lg-2: 2.4rem;
$font-lg-1: 2rem;
$font-std: 1.8rem;
$font-small: 1.6rem;
$font-tiny-1: 1.4rem;
$font-tiny-2: 1.2rem;
$font-tiny-3: 1rem;
// families
$font-family-std: 'Lato', 'Calibri', 'Verdana', 'Helvetica', 'Arial', sans-serif; // calibri does not work?
// $font-family-std: 'Open Sans', 'Calibri', 'Verdana', 'Helvetica', 'Arial', sans-serif; // calibri does not work?
// $font-family-std: 'Poppins', 'Calibri', 'Verdana', 'Helvetica', 'Arial', sans-serif; // calibri does not work?
$font-family-coding: Consolas, monospace;

// styles
$transition-std: 0.4s ease all;
